import React from "react"
import { graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { rhythm } from "../utils/typography"

const BlogIndex = ({ location }) => {

  return (
    <Layout location={location} title="Lune">
      <SEO title="Lune" />
      <div>
        The snow coming down now
        <br />
        Like December, burning up in the summer rays
        <br />
        But I'm not stumbling, like a pretender
        <br />
        Medicine flowing through my veins
        <br />
        <br />
        Heavy eyes but the tongue won't stutter
        <br />
        Mouths are moving and this heart's still fluttering
        <br />
        I'm on my own
        <br />
        <br />
        You found me in the cold,
        <br />
        Now I am wrapped within the warmth of your touch
        <br />
        There's never a moment that I'd let slip by, in your eyes
        <br />
        Are we growing old
        <br />
        And am I melting in the rays of this love?
        <br />
        Never ever gonna let you go
        <br />
        Don't let go
        <br />
        <br />
        Never gonna let you go
        <br />
        <br />
        Slow it down now
        <br />
        I cannot remember
        <br />
        Why I let them chew on my brain
        <br />
        They've come and left this town
        <br />
        In smouldering embers
        <br />
        But, my dear, I'll die in your rain
        <br />
        <br />
        Heavy eyes but the tongue won't stutter
        <br />
        Mouths are moving and this heart's still fluttering
        <br />
        I'm on my own
        <br />
        <br />
        You found me in the cold,
        <br />
        Now I am wrapped within the warmth of your touch
        <br />
        There's never a moment that I'd let slip by in your eyes
        <br />
        Are we growing old
        <br />
        And am I melting in the rays of this love?
        <br />
        Never ever gonna let you go
        <br />
        Don't let go
        <br />
        <br />
        Do you feel the love?
        <br />
        Yes, I feel the love
        <br />
        Chase it, hold it, feel it, never let it go
        <br />
        Do you feel the love?
        <br />
        Yes, I feel the love
        <br />
        <br />
        Do you feel the love?
        <br />
        Yes, I feel the love
        <br />
        Chase it, hold it, feel it, never let it go
        <br />
        Let it go
        <br />
        Let it go
        <br />
        Don't let go
        <br />
        Don't let gooooooo
        <br />
        <br />
      </div>
      {/* <p>
        Snow coming down now Like December, burning up in the summer rays But
        I'm not stumbling like a pretender Medicine flowing through my veins
      </p>
      <p>
        Heavy eyes, but the tongue won't stutter Mouths are moving and this
        heart's still fluttering I'm on my own
      </p>
      <p>
        You found me in the cold Now I am wrapped within the warmth of your
        touch There's never a moment that I let slip by in your eyes Are we
        growing old And am I melting in the rays of this love Never ever gonna
        let you go Don't let go (Never gonna let you go)
      </p>
      <p>
        Slow it down now I cannot remember why I let them chew on my brain
        'Cause they've come and left this town In smoldering embers But, my
        dear, I'll die in your rain
      </p>
      <p>
        Heavy eyes, but the tongue won't stutter Mouths are moving and this
        heart's still fluttering I'm on my own
      </p>
      <p>
        You found me in the cold Now I am wrapped within the warmth of your
        touch There's never a moment that I let slip by in your eyes Are we
        growing old And am I melting in the rays of this love? Never ever gonna
        let you go Don't let go
      </p>
      <p>
        Do you feel the love? Yes, I feel the love Chase it, hold it, feel it,
        never let it go
      </p>
      <p>Do you feel the love? Yes, I feel the love</p>
      <p>
        Do you feel the love? Yes, I feel the love Caste it, hold it, feel it,
        never let it go Let it go Let it go Don't let go Don't let go
      </p> */}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(fromNow: true)
            title
            description
          }
          wordCount {
            words
          }
        }
      }
    }
  }
`
